.main-heading {
  font-family: 'Times New Roman', Times, serif;
}

/* .background-style-value {
  background-color: #f6ffe4;
} */

.learn-more-button {
  color: #000000;
  /* color: #028090; */
}