a {
    color: inherit;
    text-decoration: none;
}

ul {
    padding-left: 0;
}

p {
    margin-bottom: 0;
}