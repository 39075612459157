/* .background-main-page {
   background: linear-gradient(to right, #ffffff 0%, #D3D3D3 100%);
   background: linear-gradient(to right, #84e4f1 0%, #d6dadb 100%);
} */

.main-heading {
   color: var(--body__color);
   font-family: 'Times New Roman';
   font-size: 45px;
}

.sub-heading {
   color: var(--body__color);
   font-family: "content-inter";
}

.button-btn {
   background-color: #000000;
   /* background-color: #028090; */
}