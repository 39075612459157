.card {
    border-radius: 10px;
    transition: transform 0.15s ease-in-out;
    /* width: calc((100vw / 2) - 20px); */
    width: 100%;
}

.card-link {
    text-decoration: none;
}

.rating-container {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    /* Adjusted transparency */
    padding: 0.5rem;
    border-radius: 0 8px 0 8px;
}

.rating-content {
    display: flex;
    align-items: center;
}

.star-icon {
    color: #f7c400;
    margin-right: 0.25rem;
}

.rating-text {
    color: #f7c400;
}

.time-text {
    color: #fff;
    font-weight: bold;
    text-align: right;
}

.card:hover {
    transform: scale(1.015);
}

/* Small devices */
@media screen and (min-width: 450px) and (max-width: 764px) {
    .card {
        width: 100%;
    }
}

@media screen and (min-width: 765px) and (max-width: 1022px),
(min-width: 1440px) {

    /* Large screens and Larger screens */
    .card {
        width: calc((100vw / 3) - 20px);
    }
}

@media screen and (min-width: 1023px) and (max-width: 1111px) {

    /* Extra-large devices (xl) */
    .card {
        width: calc((100vw / 4) - 20px);
    }
}

@media screen and (min-width: 1112px) {

    /* Extra-extra-large devices (xxl) */
    .card {
        width: calc((100vw / 5) - 20px);
    }
}